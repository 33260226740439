.pageLayout {
    grid-gap: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns:auto minmax(0, 1000px) minmax(50px, 300px) auto;
    padding: 50px 0 200px
}

@media only screen and (max-width: 800px) {
    .pageLayout {
        display: initial;
        grid-template-areas:none;
        grid-template-columns:none
    }

    .mainContent {
        max-width: none;
        padding: 10px;
        width: auto !important
    }

    textarea {
        width: 95% !important
    }
    .sideContent{
        padding: 10px;
    }
}


textarea{
    width: 98%;
}

.mainContent{
    width: 100%;
}

.submit-btn{
    margin-top: 30px;
    font-size: 14px;
}


.qa-container{
	padding: 24px 32px;
	border: 1px solid #e0e4e9;
	border-radius: 8px;
}


.user-avatar{
	width: 80px;
	height: 80px;
	border-radius: 50%;
}

.user-name{
    position: relative;
	bottom: 24px;
	margin-left: 15px;
	p{
        display: inline;
        margin-left: 15px;
    }
}

/*************** spinner *********************/
.loader {
  /* change color here */
  color: var(--primary-color);
}
.loader,
.loader div {
  box-sizing: border-box;
}
.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: currentColor;
  animation: loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.loader div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.loader div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.loader div:nth-child(3) {
  left: 56px;
  animation-delay: 0s;
}
@keyframes loader {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}