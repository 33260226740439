:root {
  --primary-color: #9400FF;
}


body {
  margin: 0;
  font-family: "Helvetica Light", "Helvetica", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header{
  color: var(--primary-color);
  font-family: "Helvetica";
  font-weight: bold;
}

body{
  color: black;
}

button{
  background-color: var(--primary-color);
  border: none;
  text-align: center;
  padding: 10px 30px 10px 30px;
   color: white;
    font-weight: bolder;
}
button:hover{
  opacity: .8;
}
button:active{
  border: 1px solid black;
}

textarea:focus{
  outline: none !important;
  border:1px solid  var(--primary-color);
  box-shadow: 0 0 10px #719ECE;
}

input[type='checkbox']{
  accent-color: var(--primary-color);
}

details {
    margin-bottom: 10px
}

summary {
    cursor: pointer;
    padding: 10px 0
}

details[open] summary {
    border-bottom: 1px solid #aaa
}
